import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux'


import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import _ from 'lodash';
import Dataservice from '../../services/dataservice'
import api from '../../services/index'
class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  async refreshToken(token){
    let tokenStore = localStorage.getItem('stTK')
    if(tokenStore == null){
      localStorage.clear()
      window.location.reload()
    }else{
      let rs = await Dataservice.refreshToken(token)
      if(rs.status == 403){
        localStorage.removeItem('userTK')
        window.location.reload()
      }
    }
   
  }
   componentDidMount(){
    this.refreshToken(localStorage.getItem('userTK'))
    // let  userRole = !_.isEmpty(localStorage.getItem('userinfo'))?JSON.parse(localStorage.getItem('userinfo')):''
    // this.nav = this.calculateNav(navigation, userRole.roleId)
  }
  render() {
    const tokenStore = localStorage.getItem('stTK')
    const tokenUser = localStorage.getItem('userTK')
    if(tokenStore == null){
      this.props.history.push("/login");
    }
    if(tokenUser == null){
      this.props.history.push("/loginstaff");
    }


    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return { userState: state.userState}
}
export default connect(mapStateToProps)(DefaultLayout);