import React, { Component } from 'react';
import {
  Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink
} from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import Dataservice from '../services/dataservice'
import api from '../services'

class CheckNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a: false,
      remainNotice: Number(sessionStorage.getItem('remainNotice')) || 0
    }
    this.notificationSystem = React.createRef();
    if (localStorage.getItem('userTK')) {
      this.actGetNotify()
      let that = this;
      function transLevel(level) {
        switch (level) {
          case 'warning':
            return 'warning';
          case 'info':
            return 'info';
          case 'dangerous':
            return 'error';
        }
      }
      window.io.socket.on('system', function (data) {
        
        that.setState({ message: data.title })
        const notification = that.notificationSystem.current;
        notification.addNotification({
          title: 'Thông báo hệ thống',
          message: data.title + '<br />' + data.content,
          position: 'bl',
          level: transLevel(data.level),
          dismissible: 'both',
          autoDismiss: 0
        });
      })
      window.refreshBell = () => {
        this.setState({ a: !this.state.a })
      }
      window.io.socket.on('message', function (data) {
        
        if (data) {
          let remain = (Number(sessionStorage.getItem('remainNotice')) || 0)
          remain++;
          sessionStorage.setItem('remainNotice', remain)
          that.setState({ remainNotice: remain })
          
        }
        const notification = that.notificationSystem.current;
        data.map(v => {
          notification.addNotification({
            title: 'Thông báo mới',
            message: v.title + '<br />' + v.content,
            position: 'bl',
            level: transLevel(v.level)
          });
        });
      })
    }
  }

  async actGetNotify() {
    var result = await Dataservice.actGetSocketInit()
    
    if (result.statusCode !== 200) {
      api.showMessage('Kết nối máy chủ thông báo thất bại ', 'Thông báo')
    }
  }
  render() {
    var remainNotice = Number(sessionStorage.getItem('remainNotice'))
    // var { remainNotice } = this.state
    
    return (
      <React.Fragment>
        <NotificationSystem ref={this.notificationSystem} allowHTML={true} />
        <NavItem className="d-md-down-none">
          {remainNotice == 0 ? <NavLink href="#"><i className="icon-bell"></i></NavLink> :
            <NavLink href="#"><i className="icon-bell"></i>{(remainNotice > 0 && remainNotice < 10) ? <Badge pill color="danger">{remainNotice}</Badge> : <Badge pill color="danger">9<sup>+</sup></Badge>} </NavLink>
          }

        </NavItem>
      </React.Fragment>)
  }
}

export default CheckNotice;
