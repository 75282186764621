import React, { Component } from "react";
// import "./css/App.css";
import App from './App';
import { Provider } from 'react-redux';
import configStore from './reducer/store';
import  api  from './services/index';

let store = configStore()

// const store = createStore(
//   rootReducers,
//   applyMiddleware(thunkMiddleware, logger)
// )
class AppContainer extends Component {
  constructor(props) {
    super(props);
    api.setStore(store)
  }

  render() {
    return (
      <Provider store={store} >
        <App />
      </Provider>
    );
  }
}

export default AppContainer;
