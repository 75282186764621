import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import dataservice from '../../../services/dataservice';
import api from '../../../services/index'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      us: [],
      canLoad: true,
      category: '1',
      isLoading: true,
      inputUsername: '',
      inputPassword: ''
    }
    this.updateUsername = this.updateUsername.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleEnter = this.handleEnter.bind(this)

  }

  async login(username, password) {
    if (!username) return api.showMessage('Vui lòng không để trống .', 'Lỗi')
    if (!password) return api.showMessage('Vui lòng không để trống .', 'Lỗi')
    let rs = await dataservice.loginStore(username, password, '', 'public')
    if (rs.status === 200) {
      api.setStoreToken(rs.data.loginInfo.token)
      localStorage.setItem('stinfo', JSON.stringify(rs.data.loginInfo))
      localStorage.setItem('stTK', rs.data.loginInfo.token)
      this.props.history.push('/loginstaff')
      console.log("login", rs.data)
    }
    else {
      api.showMessage(rs.data.message, 'Lỗi ' + rs.status)
    }
    // if (username === 'sys_admin') {
    //   let rs = await dataservice.loginSystem(username, password)
    //   if (rs.status == 200) {
    //     localStorage.setItem('stinfo', JSON.stringify(rs.data.loginInfo))
    //     localStorage.setItem('stTK', rs.data.loginInfo.token)
    //     localStorage.setItem('userinfo', JSON.stringify(rs.data.loginInfo))
    //     localStorage.setItem('userTK', rs.data.loginInfo.token)
    //     this.props.history.push('/')
    //     window.location.reload()
    //     console.log("login", rs.data)
    //   } else {
    //     api.showMessage(rs.data.message, 'Lỗi ' + rs.status)
    //   }
    // } else {
    //   let rs = await dataservice.loginStore(username, password)
    //   if (rs.status == 200) {
    //     api.setStoreToken(rs.data.loginInfo.token)
    //     localStorage.setItem('stinfo', JSON.stringify(rs.data.loginInfo))
    //     localStorage.setItem('stTK', rs.data.loginInfo.token)
    //     this.props.history.push('/loginstaff')
    //     console.log("login", rs.data)
    //     // Promise.all([rs.data,]).then(values => {
    //     //   api.setStoreToken(values[0].loginInfo.token)
    //     //   localStorage.setItem('stinfo',JSON.stringify(values[0].loginInfo))
    //     //   localStorage.setItem('stTK',values[0].loginInfo.token)
    //     // });
    //     // this.props.history.push('/loginstaff')   
    //   }
    //   else {
    //     api.showMessage(rs.data.message, 'Lỗi ' + rs.status)
    //   }
    // }
  }
  updateUsername(e) {
    this.setState({
      inputUsername: e.target.value
    })
  }
  updatePassword(e) {
    this.setState({
      inputPassword: e.target.value
    })
  }
  handleEnter(event) {
    if (event.key === 'Enter') {
      if (!this.state.inputPassword) return api.showMessage('Vui lòng không để trống .', 'Lỗi')
      if (!this.state.inputUsername) return api.showMessage('Vui lòng không để trống .', 'Lỗi')

      this.login(this.state.inputUsername, this.state.inputPassword)
    }
  }
  render() {
    const tokenStore = localStorage.getItem('stTK')
    const tokenUser = localStorage.getItem('userTK')
    if (tokenStore) {
      this.props.history.push("/loginstaff");
    }
    // if(tokenUser){
    //   this.props.history.push("/login");
    // }
    console.log(this.state)
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Đăng nhập cửa hàng</h1>
                      <p className="text-muted">Đăng nhập với tài khoản quản lý cửa hàng</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Tên đăng nhập" onChange={this.updateUsername} onKeyPress={this.handleEnter} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Mật khẩu" onChange={this.updatePassword} onKeyPress={this.handleEnter} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" onClick={() => this.login(this.state.inputUsername, this.state.inputPassword)} >Đăng nhập</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card> */}
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
