var initialState = {
  notifies: []
}

const listNotify = (state = initialState, action) => {
  // let stores = [...state.stores];
  switch (action.type) {
    case 'FETCH_NOTIFIES':
      // console.log({ action })
      return Object.assign({}, state, { notifies: action.notify });

    default: return state;
  }
}



export default listNotify;
