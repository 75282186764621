import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/VKShare-01.png'
import { Link } from 'react-router-dom'
import Dataservice from '../../services/dataservice'
import api from '../../services';
import CheckNotice from '../../controls/CheckNotice'
import { connect } from 'react-redux'
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
let isTogger = false;
class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      a: false,
      // eventKey: sessionStorage.getItem('noticeEvent').split(',') || '',
      message: '',
      remainNotice: Number(sessionStorage.getItem('remainNotice')) || 0
    }
    this.roleId = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).roleId : ''


  }

  async logout(type) {
    let storeTk = localStorage.getItem('stTk')
    let rs = await Dataservice.logout(storeTk, 1)
    
    if (type == 1) {
      localStorage.clear();
      window.location.reload()
    } else {
      localStorage.removeItem('userTK')
      localStorage.removeItem('userInfo')
      window.location.reload()
    }
  }
  handleChangePassWord() {
    this.props.history.push('/password/changepasswordstore')
  }
  async actFetchNotices(eventKey) {
    let where = {};
    if (eventKey) {
      where.eventKey = eventKey
    }
    var result = await Dataservice.actFetchNotice('', 10, '', where, '')
    
    // sessionStorage.setItem('remainNotice', result.data.remainNotice)
    this.setState({ a: !this.state.a })


  }
  handleClick() {
    if (!isTogger) {
      let eventKey = sessionStorage.getItem('noticeEvent').split(',')
      // let listNotify = sessionStorage.getItem('noticeEvent')
      // let listNotify = sessionStorage.getItem('noticeEvent').split(',')

      this.actFetchNotices(eventKey);
    }
    isTogger = !isTogger;
  }


  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    let url = '#/change-password'
    return (
      <React.Fragment>

        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 55, height: 55, alt: 'Giặt ký' }}
          minimized={{ src: logo, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <marquee direction="right" className="bold-x" style={{ color: 'lightslategray', fontSize: '1em', display: 'table', margin: '0 auto', width: '30%' }}><b>Tiệm:  {JSON.parse(localStorage.getItem('stinfo')) ? JSON.parse(localStorage.getItem('stinfo')).name + ' --- Mã cửa hàng: ' + JSON.parse(localStorage.getItem('stinfo')).storeId : ''}</b></marquee>
        <Nav className="ml-auto" navbar>
          <CheckNotice />
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              {/* <img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" /> */}

              <span style={{ fontSize: '1em' }}> <strong>Xin chào, {JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).fullName + ' - Mã số NV: '+JSON.parse(localStorage.getItem('userinfo')).userId: ''}</strong></span>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              {/* <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem> */}
              <DropdownItem href={url}><i className="fa fa-sign-out"></i>Đổi mật khẩu</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => this.logout(2)}><i className="fa fa-sign-out"></i> Đăng xuất cá nhân</DropdownItem>
              <DropdownItem style={this.roleId.includes(4) ? { display: 'flex' } : { display: 'none' }} onClick={() => this.logout(1)}><i className="fa fa-sign-out"></i> Đăng xuất cửa hàng </DropdownItem>
              <DropdownItem style={this.roleId.includes(7) ? { display: 'flex' } : { display: 'none' }} onClick={() => this.logout(1)}><i className="fa fa-sign-out"></i> Đăng xuất cửa hàng </DropdownItem>

            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        <Link to="#" onClick={this.handleClick.bind(this)}><AppAsideToggler className="d-md-down-none" /></Link>
        <Link to="#" onClick={this.handleClick.bind(this)}><AppAsideToggler className="d-lg-none" mobile ref={this.asideTogglerRef} /></Link>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
const mapStateToProps = (state) => {
  return {
    listNotify: state.listNotify
  }
}
export default connect(mapStateToProps)(DefaultHeader);




