import { combineReducers } from 'redux';
import userState from './userReducer';
import uiState from './uiReducer';
import listNotify from './listNotify'
import listUsers from './listUsers'
import listStoreUsers from './listStoreUsers'
import listVouchers from './listVouchers'
import listStoreAreas from './listStoreAreas'
const rootReducer = combineReducers({listNotify, userState, uiState,listUsers,listStoreUsers,listStoreAreas,listVouchers });
export default rootReducer;
