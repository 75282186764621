import React, { Component } from "react";
import DataService from "../../../services/dataservice";
import Barcode from "react-barcode";
import api from "../../../services/index";
import queryString from "query-string";
import _ from "lodash";
import moment from "moment";
class BillInfoForCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      large: false,

      data: []
    };
  }
  componentDidMount() {
    this.loadData();
  }
  async loadData() {
    let barcode = queryString.parse(this.props.location.search).barCode;
    let billId = queryString.parse(this.props.location.search).billId;
    let apiVersion = "public";
    let rs = await DataService.getBillInfo(
      barcode,
      billId,
      apiVersion,
      undefined
    );
    if (rs.status == 200) {
      this.setState({ data: rs.data.bill });
    } else {
      api.showMessage(rs.data.message, "Lỗi " + rs.status);
    }
  }
  render() {

    var storeId =
      this.state.data && this.state.data.storeId ? this.state.data.storeId : {};
    return (
      <React.Fragment>
        <div class="invoice-box">
          {/* <div style={{ textAlign: 'center' }} ><img style={{ width: 'auto', height: '191px' }} src='/img-logo.jpg' alt='' /></div>
          <div style={{ fontSize: '35px', textAlign: 'center' }} className='mt-3 mb-3'><span>Tiệm Giặt Ký</span></div>
          <div style={{ fontSize: '20px', textAlign: 'center' }} className='mt-2 mb-2'><a href='https://giatky.vn' target='_blank'>https://giatky.vn </a></div> */}
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className="top">
                {/* <td colSpan="2">

                </td> */}
                <td colSpan="2">
                  <table>
                    <tr>
                      <td>
                        <div className="text-center">
                          <img
                            style={{ width: "auto", height: "191px" }}
                            src="/img-logo.jpg"
                            alt=""
                          />
                        </div>
                        <div
                          style={{ fontSize: "35px", textAlign: "center" }}
                          className="mt-3 mb-3"
                        >
                          <span>Tiệm Giặt Ký</span>
                        </div>
                        {/* <div
                          style={{ fontSize: "20px", textAlign: "center" }}
                          className="mt-2 mb-2"
                        >
                          <a href="https://giatky.vn" target="_blank">
                            https://giatky.vn{" "}
                          </a>
                        </div> */}
                      </td>
                      <td style={{ fontSize: "18px", fontWeight: "bold" }}>
                        <div>
                          {this.state.data.id
                            ? "Mã hóa đơn: " + this.state.data.id
                            : ""}
                        </div>
                        <br />

                        <Barcode width={2} value={this.state.data.barCode} />
                        <div>
                          <br />
                          {this.state.data.currentStage && this.state.data.currentStage.name
                            ? "Trạng thái đơn hàng: " + this.state.data.currentStage.name
                            : ""}
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="2">
                  <table>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        <div>Thông tin cửa hàng</div>
                        <br />
                        Cửa hàng: {storeId.name}
                        <br />
                        Địa chỉ: {storeId.address}
                        <br />
                        SĐT: {storeId.phone}
                        <br />
                        Website:{" "}
                        <a href="https://giatky.vn" target="_blank">
                          https://giatky.vn{" "}
                        </a>
                      </td>

                      <td >
                        <div style={{ fontWeight: "bold" }}>
                          Thông tin khách hàng
                        </div>
                        <br />{" "}
                        {this.state.data && this.state.data.customerName
                          ? "Tên khách hàng: " + this.state.data.customerName
                          : ""}
                        <br />
                        {this.state.data && this.state.data.customerId
                          ? "Địa chỉ: " + this.state.data.customerId.address
                          : ""}
                        <br />{" "}
                        {this.state.data && this.state.data
                          ? "Email: " + this.state.data.email
                          : ""}
                        <br />
                        SĐT:{" "}
                        {this.state.data && this.state.data.customerPhone
                          ? this.state.data.customerPhone
                          : ""}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* <tr className="heading">
                <td>Payment Method</td>

                <td>Check #</td>
              </tr>

              <tr className="details">
                <td>Check</td>

                <td>1000</td>
              </tr> */}
              <tr>
                <td colSpan="10">
                  <hr />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Địa chỉ nhận đồ:</td>
                <td>
                  {" "}
                  {this.state.data && this.state.data.receivedAddress
                    ? this.state.data.receivedAddress
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}> Hạn trả đồ:</td>
                <td>
                  {this.state.data && this.state.data.dueDate
                    ? moment(this.state.data.dueDate).format("HH:mm DD-MM-YYYY")
                    : ""}
                </td>
              </tr>
              <tr />
              <tr>
                <td style={{ fontWeight: "bold" }}>Tình trạng thanh toán:</td>
                <td>
                  <b>
                    {this.state.data && this.state.data.isPay
                      ? "Đã thanh toán"
                      : "Chưa thanh toán"}
                  </b>
                </td>
              </tr>
              <tr className="heading">
                {/* <td>STT</td> */}
                <td>
                  <span className="mr-5">STT </span>
                  <span>Sản phẩm</span>
                </td>

                <td>Đơn giá</td>
              </tr>
              {this.state.data && this.state.data.billDetailIds
                ? this.state.data.billDetailIds.map((d, index) => {
                  return (
                    <React.Fragment>
                      <tr className="item">
                        <td>
                          <span className="mr-5">{index + 1} </span>
                          <span>{d.productName}</span>
                        </td>
                        <td>
                          {d.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
                : ""}


              {/* <tr className="item">
                <td>Hosting (3 months)</td>

                <td>$75.00</td>
              </tr>

              <tr className="item last">
                <td>Domain name (1 year)</td>

                <td>$10.00</td>
              </tr> */}

              <tr className="heading">
                <td />

                <td />
              </tr>
              <tr className="total">
                <td />
                <td>
                  <table>
                    <tr>
                      <td>Mã giảm giá:</td>
                      <td>
                        {this.state.data && this.state.data.voucher
                          ? this.state.data.voucher
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Giảm giá:</td>
                      <td>
                        {this.state.data &&
                          this.state.data.needPay &&
                          this.state.data.totalPrice
                          ? (
                            this.state.data.totalPrice -
                            this.state.data.needPay
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"
                          : "0"}
                      </td>
                    </tr>
                    <tr>
                      <td>Thành tiền:</td>
                      <td>
                        {this.state.data && this.state.data.needPay
                          ? this.state.data.needPay
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"
                          : "0"}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Thời gian tạo: </td>
                      <td> {this.state.data && this.state.data.billDetailIds
                            ?  
                              moment(this.state.data.createdAt).format(
                                "HH:mm DD-MM-YYYY"
                              )
                            : ""}</td>
                   
                    </tr> */}
                  </table>
                  {/* Voucher:{" "}
                  {this.state.data && this.state.data.voucher
                    ? this.state.data.voucher
                    : "0"}
                  <br />
                  Giảm giá:{" "}
                  {this.state.data &&
                  this.state.data.needPay &&
                  this.state.data.totalPrice
                    ? (this.state.data.totalPrice - this.state.data.needPay)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "0"}
                  <br />
                  Thành tiền:{" "}
                  {this.state.data && this.state.data.needPay
                    ? this.state.data.needPay
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "0"} */}
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div>
            <p>
              <span>
                Người tạo:{" "}
                {this.state.data.createdBy
                  ? this.state.data.createdBy.fullName
                  : ""}
              </span>
              <br />
              <span >
                  Mã nhân viên:{this.state.data.createdBy
                  ? this.state.data.createdBy.id
                  : ""}
              </span>
              <br />
            
            </p>
          </div> */}
          <div
            style={{
              fontSize: "18px",
              fontStyle: "italic",
              marginTop: "22px",
              color: "black"
            }}
          >
            <p style={{ fontSize: "14px", textAlign: "left", color: "black" }}>
              Qui định của hệ thống:
              <br /> - Quý khách vui lòng kiểm tra đồ tại cửa hàng sau khi nhận
              đồ. Mọi khiếu nại sau khi Khách hàng đi khỏi cửa hàng sẽ không
              được giải quyết.
              <br />
              - Thời gian lưu đồ tối đa là 15 ngày kể từ ngày xuất Hóa đơn. Quá
              thời gian trên, chúng tôi không chịu trách nhiệm nếu có mất mát.
              <br />
              - Quý khách đã được nhân viên cửa hàng chỉ dẫn các qui định trên
              và đồng ý khi nhận Hóa đơn. <br />- Giặt là là nghề có rủi ro.
              Chúng tôi đền bù tối đa 10 lần giá trị lượt giặt của sản phẩm đó,
              nếu có hỏng hóc, mất mát phát sinh và phải xuất trình hoá đơn gốc.
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              Xin cảm ơn quý khách đã sử dụng Giặt ký.
            </p>
          </div>
          <div className="text-center">
            <p>
              <span>
                Hóa đơn tạo bởi:{" "}
                <strong>
                  {" "}
                  {this.state.data.createdBy
                    ? this.state.data.createdBy.fullName
                    : ""}
                </strong>
                , mã số NV:<strong>
                  {this.state.data.createdBy
                    ? this.state.data.createdBy.id
                    : ""}</strong>
                ,Tạo lúc:
                  <strong>
                  {this.state.data && this.state.data.billDetailIds
                    ? moment(this.state.data.createdAt).format(
                      "HH:mm DD-MM-YYYY"
                    )
                    : ""}
                </strong>
              </span>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default BillInfoForCustomer;
