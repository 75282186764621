
import api from './index'
import axios from 'axios';
import config from './config';
import moment from 'moment'
const request = {
  callApi: async (endpoint, apiVersion, body, method = 'GET', token = null) => {
    try {
      token = token || localStorage.getItem('userTK')

      let option = {
        method,
        url: `${`${process.env.REACT_APP_HOST || config.HOST}`}/${endpoint}`,

        data: body,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token,
          // 'Accept-Language': api.getLang(),
          'Api-Version': apiVersion
        }
      }
      console.log({ option: option })
      // console.log('body data pst',)
      let response = await axios(option);

      // if (response.headers['new-token']) {
      //     //TODO: update token localstorage
      //     console.log("Received new token: ", response.headers['new-token'])
      //     localStorage.setItem('RFTK-GK')
      console.log('---------------------------RESPONSE------------------------------\n', 'option =>', option, '\n data ===>', response)
      // }
      return response;

    } catch (error) {
      // let response = await axios(option);
      return error.response;
      // console.log(error.response)
      // console.log(error.response)

    }

  },
  report: async (url, apiVersion, token) => {
    try {
      let options = {
        url: `${`${process.env.REACT_APP_HOST || config.HOST}`}/${url}`,
        method: "GET",
        responseType: "blob", // important
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
          "api-version": apiVersion
        }
      };
      let response = await axios(options);
      console.log({options})
      console.log({response})
      url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url; //Custom-File-Name
      link.setAttribute('download', `report_${moment().format('DDMMYYHHmm')}.xlsx`);
      document.body.appendChild(link);
      link.click();

      return response;
    } catch (error) {
      return error.response || error;
    }
  },

}

// .replace(/\"/g, '')
// .replace(/\"/g, '')
export default request
