
var initialState = {
    token: {
        "token": "b88ec002-507a-48df-8547-ab8d6d02b9ba",
        "expiredAt": "2018-04-11T10:15:00.384Z"
    },
    // uuid: '',
    // user: {
    //     "name": "Tùng Đỗ",
    //     "email": "tungdt2504@gmail.com",
    // },
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_TOKEN":
            return Object.assign({}, state, { token: action.token })
        case "SET_STORE_TOKEN":
            return Object.assign({}, state, { storeToken: action.token })
        case "SET_USER_INFO":
            return Object.assign({}, state, { user: action.data })
        // case "SET_LIST_MEMBER":
        //     return Object.assign({}, state, { memberCards: action.memberCards })
        default:
            return state;
    }
}
export default userReducer;