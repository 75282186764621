import { createStore, applyMiddleware } from 'redux'
import rootReducer from './rootReducer'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'

const configureStore = () => {
    const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, logger))
    return store
}
export default configureStore
