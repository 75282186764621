// import sailsIOClient from 'sails.io.js'
import config from './config'
// Instantiate the socket client (`io`)
// (for now, you must explicitly pass in the socket.io client when using this library from Node.js)
// var io = sailsIOClient(socketIOClient);

// Set some options:
// (you have to specify the host and port of the Sails backend when using this library from Node.js)

// ...
// window.io.sails.url = config.HOST;
// window.io.sails.autoConnect = true;
// window.io.sails.connect(config.HOST)
// window.io.socket.on('connect', function onConnect() {
  //  console.log('This socket is now connected to the Sails server.');
// });


const socketio = {
  callApi: (endpoint, apiVersion, body, method = 'GET', token = null) => {
    token = token || localStorage.getItem('userTK')
    let option = {
      url: endpoint,
      method,
      data: body,
      headers: {
        'Authorization': token,
        // 'Accept-Language': api.getLang(),
        'Api-Version': apiVersion
      }
    }

    // console.log({ option: option })
    // console.log('body data pst',)
    let response = new Promise((resolve, reject) => {
      console.log('socket', { option })

      window.io.socket.request(option,
        function (body, JWR) {
          console.log('jwr',JWR)
          resolve(JWR)
        }, function (error) {
          console.log('error',error)
          reject(error);
        })
    })
    return response;//{}
    // if (response.headers['new-token']) {
    //     //TODO: update token localstorage
    //     console.log("Received new token: ", response.headers['new-token'])
    //     localStorage.setItem('RFTK-GK')
    // console.log('---------------------------RESPONSE------------------------------\n', 'option =>', option, '\n data ===>', response)
    // }
    // return response;


  },
  io: window.io

}

// .replace(/\"/g, '')
// .replace(/\"/g, '')
export default socketio
