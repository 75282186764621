import React, { Component } from 'react';
import { connect } from 'react-redux'
import MessageBox from './messagebox'
import Loading from './loading'
import ConfirmBox from './conFirmbox'
class UI extends Component {

    render() {
        let uiStatus = this.props.uiState
        console.log('props',uiStatus)
        return (
                <div className="Ui">
                    {uiStatus.showMessage?<MessageBox show={uiStatus.showMessage} message={uiStatus.message} title={uiStatus.titleMessage} />:null}
                    {uiStatus.showLoading?<Loading show={uiStatus.showLoading} />:null}
                    {uiStatus.showConfirmBox?<ConfirmBox show ={uiStatus.showConfirmBox} titleConfirm={uiStatus.titleConfirm} bodyConfirm ={uiStatus.bodyConfirm} bodyConfirmOK={uiStatus.bodyConfirmOK} bodyConfirmNO={uiStatus.bodyConfirmNO} titleBtnOK={uiStatus.titleBtnOK} titleBtnNO={uiStatus.titleBtnNO}/>:null}   
                </div>
                )
        }
    }


const mapStateToProps = (state) => {
    return { uiState: state.uiState }
}
export default connect(mapStateToProps)(UI);
