import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

// const Cards = Loadable({
//   loader: () => import('./views/Base/Cards'),
//   loading: Loading,
// });

// const Carousels = Loadable({
//   loader: () => import('./views/Base/Carousels'),
//   loading: Loading,
// });

// const Collapses = Loadable({
//   loader: () => import('./views/Base/Collapses'),
//   loading: Loading,
// });

// const Dropdowns = Loadable({
//   loader: () => import('./views/Base/Dropdowns'),
//   loading: Loading,
// });

// const Forms = Loadable({
//   loader: () => import('./views/Base/Forms'),
//   loading: Loading,
// });

// const Jumbotrons = Loadable({
//   loader: () => import('./views/Base/Jumbotrons'),
//   loading: Loading,
// });

// const ListGroups = Loadable({
//   loader: () => import('./views/Base/ListGroups'),
//   loading: Loading,
// });

// const Navbars = Loadable({
//   loader: () => import('./views/Base/Navbars'),
//   loading: Loading,
// });

// const Navs = Loadable({
//   loader: () => import('./views/Base/Navs'),
//   loading: Loading,
// });

// const Paginations = Loadable({
//   loader: () => import('./views/Base/Paginations'),
//   loading: Loading,
// });

// const Popovers = Loadable({
//   loader: () => import('./views/Base/Popovers'),
//   loading: Loading,
// });

// const ProgressBar = Loadable({
//   loader: () => import('./views/Base/ProgressBar'),
//   loading: Loading,
// });

// const Switches = Loadable({
//   loader: () => import('./views/Base/Switches'),
//   loading: Loading,
// });

// const Tables = Loadable({
//   loader: () => import('./views/Base/Tables'),
//   loading: Loading,
// });

// const Tabs = Loadable({
//   loader: () => import('./views/Base/Tabs'),
//   loading: Loading,
// });

// const Tooltips = Loadable({
//   loader: () => import('./views/Base/Tooltips'),
//   loading: Loading,
// });

// const BrandButtons = Loadable({
//   loader: () => import('./views/Buttons/BrandButtons'),
//   loading: Loading,
// });

// const ButtonDropdowns = Loadable({
//   loader: () => import('./views/Buttons/ButtonDropdowns'),
//   loading: Loading,
// });

// const ButtonGroups = Loadable({
//   loader: () => import('./views/Buttons/ButtonGroups'),
//   loading: Loading,
// });

// const Buttons = Loadable({
//   loader: () => import('./views/Buttons/Buttons'),
//   loading: Loading,
// });

const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});
const DashboardView = Loadable({
  loader: () => import('./views/Dashboard/DashboardView'),
  loading: Loading,
});

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('./views/Pages/ChangePassword'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/QuanLyNhanVien/Users'),
  loading: Loading,
});

const StoreUsers = Loadable({
  loader: () => import('./views/QuanLyNhanVien/StoreUsers'),
  loading: Loading,
});
const User = Loadable({
  loader: () => import('./views/QuanLyNhanVien/Users/User'),
  loading: Loading,
});

const Customer = Loadable({
  loader: () => import('./views/Customer/CustomerService/Customer'),
  loading: Loading,
});
const ManagermentUser = Loadable({
  loader: () => import('./views/Managerment/ManagermentUser'),
  loading: Loading,
})
const ManagermentStoreUser = Loadable({
  loader:() => import('./views/Managerment/ManagermentStoreUser'),
  loading: Loading,
})
const UpdateProductPrice = Loadable({
  loader: () => import('./views/QuanLyCuaHang/UpdateProductPrice/UpdatePrice'),
  loading:Loading,
})
const NewBill = Loadable({
  loader: () => import('./views/Bill/NewBill'),
  loading: Loading,
})

const ProcessBill = Loadable({
  loader: () => import('./views/Bill/ProcessBill'),
  loading: Loading,
})

const WorkshopBill = Loadable({
  loader: () => import('./views/Bill/WorkshopBill'),
  loading: Loading,
})

const FinishBill = Loadable({
  loader: () => import('./views/Bill/FinishBill'),
  loading: Loading,
})


const NeedPayBill = Loadable({
  loader: () => import('./views/Bill/NeedPayBill'),
  loading: Loading,
})

const PayedBill = Loadable({
  loader: () => import('./views/Bill/PayedBill'),
  loading: Loading,
})


const AllBill = Loadable({
  loader: () => import('./views/Bill/AllBill'),
  loading: Loading,
})
const BillDebt = Loadable({
  loader: () => import('./views/Bill/BillDebt'),
  loading: Loading,
})

const Stores = Loadable({
  loader: () => import('./views/QuanLyCuaHang/Stores'),
  loading: Loading,
});
const PasswordStore = Loadable({
  loader: () => import('./views/QuanLyCuaHang/ChangePasswordStore'),
  loading: Loading,
});
const Email = Loadable({
  loader: () => import('./views/Report/Email'),
  loading: Loading,
});
const Sms = Loadable({
  loader: () => import('./views/Report/Sms'),
  loading: Loading,
});
const FinishWork = Loadable({
  loader: () => import('./views/Report/FinishWork'),
  loading: Loading,
});
const TurnOver = Loadable({
  loader: () => import('./views/Report/TurnOver'),
  loading: Loading,
});
const OverTime = Loadable({
  loader: () => import('./views/Report/OverTime'),
  loading: Loading,
});

const PartnerTurnOver = Loadable({
  loader: () => import('./views/Report/PartnerTurnOver'),
  loading: Loading,
});
const Rating = Loadable({
  loader: () => import('./views/Report/Rating'),
  loading: Loading,
});

const StoreTurnOver = Loadable({
  loader: () => import('./views/Report/StoreTurnOver'),
  loading: Loading,
});
const Debts = Loadable({
  loader: () => import('./views/Report/Debts'),
  loading: Loading,
});
const Debt = Loadable({
  loader: () => import('./views/Report/Debts/Debt'),
  loading: Loading,
});
const PrintBill = Loadable({
  loader: () => import('./views/Customer/CustomerService/Print'),
  loading: Loading,

})

const Notifies = Loadable({
  loader: () => import('./views/Notice/Notifies'),
  loading: Loading,
});

const NoticeSystem = Loadable({
  loader: () => import('./views/Notice/NoticeSystem'),
  loading: Loading,
});

const storeStar = Loadable({
  loader: () => import('./views/Report/Star/Star'),
  loading: Loading,
});

const storeRatting = Loadable({
  loader: () => import('./views/Report/StoreRatting/StoreRatting'),
  loading: Loading,
});
const CustomerUseService = Loadable({
  loader: () => import('./views/Report/CustomerUseService/CustomerUseService'),
  loading:Loading,
})
const DebtByDay = Loadable({
  loader: () => import('./views/Report/DebtByDay/DebtByDay'),
  loading:Loading,
})

const ListVoucher = Loadable({
  loader: () => import('./views/QuanLyVoucher/Vouchers/Vouchers'),
  loading:Loading,
})
const ListVoucherCleanId = Loadable({
  loader: () => import('./views/QuanLyVoucher/Vouchers/VouchersCleanId'),
  loading:Loading,
})
const VoucherCleanId = Loadable({
  loader: () => import('./views/QuanLyVoucher/Vouchers/VoucherCleanId'),
  loading:Loading,
})
const Voucher = Loadable({
  loader: () => import('./views/QuanLyVoucher/Vouchers/Voucher'),
  loading:Loading,
})
const RevenueStructure=Loadable({
  loader:()=>import ('./views/Report/RevenueStructure/RevenueStructure'),
  loading:Loading,
})
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  //
  //
  { path: '/quanlycuahang/stores', exact: true, name: 'Cập nhật cửa hàng', component: Stores },
  { path: '/password/changepasswordstore', name: 'Password', component: PasswordStore },
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard',exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/dashboard/:id',exact: true, name: 'Nội dung thông báo', component: DashboardView },
  // { path: '/theme', exact: true, name: 'Theme', component: Colors },
  // { path: '/theme/colors', name: 'Colors', component: Colors },
  // { path: '/theme/typography', name: 'Typography', component: Typography },
  // // { path: '/base', exact: true, name: 'Base', component: Cards },
  // // { path: '/base/cards', name: 'Cards', component: Cards },
  // { path: '/base/forms', name: 'Forms', component: Forms },
  // { path: '/base/switches', name: 'Switches', component: Switches },
  // { path: '/base/tables', name: 'Tables', component: Tables },
  // { path: '/base/tabs', name: 'Tabs', component: Tabs },
  // { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  // { path: '/base/carousels', name: 'Carousel', component: Carousels },
  // { path: '/base/collapses', name: 'Collapse', component: Collapses },
  // { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  // { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  // { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  // { path: '/base/navbars', name: 'Navbars', component: Navbars },
  // { path: '/base/navs', name: 'Navs', component: Navs },
  // { path: '/base/paginations', name: 'Paginations', component: Paginations },
  // { path: '/base/popovers', name: 'Popovers', component: Popovers },
  // { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  // { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  // { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  // { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  // { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  // { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  // { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  // { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  // { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  // { path: '/icons/flags', name: 'Flags', component: Flags },
  // { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  // { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  // { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  // { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  // { path: '/notifications/badges', name: 'Badges', component: Badges },
  // { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/change-password', name: 'Đổi mật khẩu', component: ChangePassword },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/users/user', exact: true, name: 'Users', component: Users },
  { path: '/users/user/:id', exact: true, name: 'User Details', component: User },
  { path: '/users/storeUser', exact: true, name: 'Users Store', component: StoreUsers },
  { path: '/previewPrint/:id', name: 'In hóa đơn', component: PrintBill },
  { path: '/customer/search', name: 'Khách hàng', component: Customer },
  { path: '/usermanagerment/user', name: 'Người dùng', component: ManagermentUser },
  { path: '/usermanagerment/storeUser',name:'Nhân viên cửa hàng',component:ManagermentStoreUser},
  { path: '/bill/newbill', name: 'Hóa đơn mới nhận', component: NewBill },
  { path: '/bill/process-bill', name: 'Hóa đơn đang xử lý', component: ProcessBill },
  { path: '/bill/workshop-bill', name: 'Hóa đơn chuyển xưởng', component: WorkshopBill },
  { path: '/bill/finish-bill', name: 'Hóa đơn đã hoàn thành', component: FinishBill },
  { path: '/bill/need-pay-bill', name: 'Hóa đơn cần trả', component: NeedPayBill },
  { path: '/bill/payed-bill', name: 'Hóa đơn đã trả', component: PayedBill },
  { path: '/bill/all-bill', name: 'Tất cả hóa đơn', component: AllBill },
  { path: '/bill/debt-bill', name: 'Hóa đơn nợ', component: BillDebt },
  


  { path: '/report/email', exact: true, name: 'Email', component: Email },
  { path: '/report/sms', exact: true, name: 'Sms', component: Sms },
  { path: '/report/finish-work', exact: true, name: 'Finish Work', component: FinishWork },
  { path: '/report/turnover', exact: true, name: 'TurnOver', component: TurnOver },
  { path: '/report/overtime', exact: true, name: 'Ngày tăng ca', component: OverTime },
  { path: '/report/partner-store/turnover', exact: true, name: 'Doanh số chuyển xưởng', component: PartnerTurnOver },
  { path: '/report/rating', exact: true, name: 'Đánh giá người dùng', component: Rating },
  { path: '/report/store/turnover', exact: true, name: 'Doang số cửa hàng', component: StoreTurnOver },
  { path: '/report/debt-bill-ispay-inday', name: 'Danh sách nợ theo từng ngày', component: DebtByDay},

  { path: '/report/store/star', exact: true, name: 'Tổng kết số lần đánh giá', component: storeStar },
  { path: '/report/store/ratting', exact: true, name: 'Tổng kết số lần nhận xét theo từng hạng mục', component: storeRatting },
  { path: '/report/CustomerUseService', exact: true, name: 'CustomerUseService', component: CustomerUseService },


  { path: '/debt', exact: true, name: 'Debts', component: Debts },
  { path: '/debt/storeId=/:storeId/userId=/:userId', exact: true, name: 'Debt', component: Debt },
  { path: '/quanlycuahang/updateprice', exact: true, name: 'Cập nhật giá sản phẩm', component: UpdateProductPrice },
  { path: '/quanlyvoucher/listVoucher', exact: true, name: 'Danh sách voucher', component: ListVoucher },
  { path: '/quanlyvoucherCleanId/listVoucherCleanId', exact: true, name: 'Danh sách voucher CleanId', component: ListVoucherCleanId },
  { path: '/quanlyvoucher/:voucherid', exact: true, name: 'Chi tiết voucher', component: Voucher },
  { path: '/quanlyvoucherCleanId/:voucherid', exact: true, name: 'Chi tiết voucher CleanId', component: VoucherCleanId },
  
  { path: '/notice/notifies', exact: true, name: 'Thông báo', component: Notifies },

  { path: '/notice/system', exact: true, name: 'Thông báo hệ thống', component: NoticeSystem },

  {path:"/report/revenue-structure",exact:true,name:'Báo cáo doanh thu theo sản phẩm',component:RevenueStructure},



];

export default routes;
